@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* ul > li::marker {
  color: theme('colors.orange.600');
} */

@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

.sib-hide-loader-icon {
  @apply hidden;
}

.sib-form-message-panel {
  @apply hidden;
}

.sib-form-message-panel--active {
  @apply block;
}
